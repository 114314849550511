import React, {useEffect, useState} from 'react';
import LightAnimate from "../../component/lightAnimate";
import useRem from "../../common/useRem";
import MCarouselType2 from "../../component/mobile/mCarouselType1/mCarouselType2";
import AnimateScroll from "../../component/animateScroll/animateScroll";
import {isChinese} from "../../common/tool";
import useAlert from "../../component/mAlert/useAlert";
import axios from 'axios';
const ContentM = () => {
    const xs=useRem();
    const {setAlert}=useAlert();
    const [newsData, setNewsData] = useState([0,0,0]);
    useEffect(()=>{
        axios.get("./config/news").then((res)=>{
            setNewsData(res.data);
        })
    },[]);
    return (
        <div className={'contentM'}>
            <AnimateScroll aniamteType={2}><div className={'bannerTextInfoSmall'}>
                Macro and Crypto Concepts,<br/>Clearly Explained. Stay<br/>Informed with SignalPlus and<br/>Join Our <span>Vibrant Community<LightAnimate w={"100%"} h={xs*6} x={0} y={"88%"} /></span><br/>to Enrich Our Knowledge Together.
            </div></AnimateScroll>
            <AnimateScroll aniamteType={3}><div className={'bannerTextTitle'}>Options 101</div></AnimateScroll>
            <AnimateScroll aniamteType={2}><div className={'bannerTextField center'} style={{fontSize:Math.floor(xs*16)}}>
                Our concise and easy-to-understand resources will equip you with the knowledge and confidence you need to succeed in options trading. Start your journey to option trading mastery today!
            </div></AnimateScroll>
            <MCarouselType2 imgwidth={Math.floor(xs*288)} imageheight={Math.floor(xs*140)}/>

            <div className={"introbox"} style={{width:Math.floor(xs*327)}}>
                <AnimateScroll aniamteType={2}><div className={'card'}>
                    <div className={'icon'}><img alt={''} src={'./img/common/videoimgIcon.png'} /></div>
                    <div className={'title'}>Cryptocurrency Options Basics</div>
                    <p>Before we start, let's first take a look at how options can help achieve different investment goals.
                        Thanks to the versatility of options, you can essentially bet on any movement of the market or specific asset. If your prediction is correct, you can make money either the underlying asset goes up or down or even sideways, through options. Aside from versatility, leverage is another</p>
                    <a href={isChinese()?"https://rowan-hawk-725.notion.site/232a3f1ad273463fbf6e6dd4d51faae8":"https://rowan-hawk-725.notion.site/Cryptocurrency-Options-Basics-da9bd01466f34a5f999101bd6c243508"}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'card'}>
                    <div className={'icon'}><img alt={''} src={'./img/common/videoimgIcon.png'} /></div>
                    <div className={'title'}>Cryptocurrency Options Intermediate</div>
                    <p>
                        In the world of cryptocurrency, another popular derivative is Perpetual Futures. A futures contract is a binding agreement to trade a specific asset, such as bitcoin or ether, at a certain
                    </p>
                    <a href={isChinese()?"https://rowan-hawk-725.notion.site/6973974cc4a244bc84872fad6ddd371f":"https://rowan-hawk-725.notion.site/Cryptocurrency-Options-Intermediate-79b58139189047cfa1e66a0bdb6293ef"}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                </div></AnimateScroll>
                <AnimateScroll aniamteType={2}><div className={'card'}>
                    <div className={'icon'}><img alt={''} src={'./img/common/videoimgIcon.png'} /></div>
                    <div className={'title'}>Cryptocurrency Options Advanced</div>
                    <p>
                        In SignalPlus Toolkit, you will find a series of tools to display volatility related information of the options market from different angels, under the Volatility section - you can find more details
                    </p>
                    <a href={isChinese()?"https://rowan-hawk-725.notion.site/e1a7855c3d58438d892cb2d7715cc653":"https://rowan-hawk-725.notion.site/Cryptocurrency-Options-Advanced-ddccc1b820894f15b64a25e2d208d2af"}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a>
                </div></AnimateScroll>
            </div>

            <AnimateScroll aniamteType={3}><div className={'bannerTextTitle'} style={{marginTop:Math.floor(xs*96)}}>Read Our Commentary</div></AnimateScroll>
            <AnimateScroll aniamteType={2}><div className={'bannerTextField center'} style={{fontSize:Math.floor(xs*16)}}>
                Our commentary amalgamates our insights across macro, FICC, equities, crypto, and volatility space to offer a succinct, digestible, and differentiated product for our readership.  Stay informed and educated with our cross-market analysis.
            </div></AnimateScroll>

            <div className={"introbox"} style={{paddingTop:Math.floor(xs*48),width:Math.floor(xs*327)}}>
                {newsData&&newsData.map((item,index)=>{
                    return (
                        <AnimateScroll key={item.img} aniamteType={index%2?3:2}>
                            <div className={'cardImg'}>
                                <div style={{height:Math.floor(327*405/720)}}><img alt={''} src={item.img} /></div>
                                <div className={'morePar'}><a href={item.href}><div className={'more'}>Learn More<img alt={''} src={'./img/pc/jiantou.png'}/></div></a></div>
                            </div>
                        </AnimateScroll>
                    )
                })}
            </div>


            <AnimateScroll aniamteType={2}><div className={'bannerTextTitle'} style={{marginTop:Math.floor(xs*96)}}>Join Our Community</div></AnimateScroll>

            <div className={'flexIcon'}>
                <AnimateScroll aniamteType={4} obo={0}><div className={'icon'}><a href={'https://discord.com/invite/signalplus'}><img src={'./img/common/c0.png'} alt={''} /></a></div></AnimateScroll>
                <AnimateScroll aniamteType={4} obo={1}><div className={'icon'} onClick={()=>{setAlert("wechat","alert",null)}}><img src={'./img/common/c1.png'} alt={''} /></div></AnimateScroll>
                <AnimateScroll aniamteType={4} obo={2}><div className={'icon'}><a href={'https://twitter.com/SignalPlus_Web3'}><img src={'./img/common/c2.png'} alt={''} /></a></div></AnimateScroll>
                <AnimateScroll aniamteType={4} obo={3}><div className={'icon'}><a href={'https://t.me/SignalPlus_Official'}><img src={'./img/common/c3.png'} alt={''} /></a></div></AnimateScroll>
            </div>
            <AnimateScroll aniamteType={3}>
                <div className={'bannerTextField center'} style={{marginTop:Math.floor(xs*32)}}>Be part of our vibrant community - connect with like-minded individuals, stay up to date with the latest news,<br/> and engage in stimulating conversations. Follow us now and join in the discussions!
                </div>
            </AnimateScroll>
            <div className={'community'}>
                <AnimateScroll aniamteType={3}><div className={'smallList'}><img alt={''} src={'./img/common/csmall.png'} />AMA</div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'smallList'}><img alt={''} src={'./img/common/csmall.png'} />Trading Competition</div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'smallList'}><img alt={''} src={'./img/common/csmall.png'} />Lessons</div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'smallList'}><img alt={''} src={'./img/common/csmall.png'} />Games</div></AnimateScroll>
                <AnimateScroll aniamteType={3}><div className={'smallList'}><img alt={''} src={'./img/common/csmall.png'} />Derivatives ChatGPT</div></AnimateScroll>
            </div>
        </div>
    );
};

export default ContentM;